jQuery(function () {
	menuInit();
	collapseComponent()
	setBackground();
	$.fancybox.defaults.parentEl = "form";
	$('.back-to-top').hide()
	$("header").toggleClass("scrolling", $(window).scrollTop() > 0);
	$(window).on("scroll", function () {
		$("header").toggleClass("scrolling", $(window).scrollTop() > 0);
		$(".logo").toggleClass("active", $(window).scrollTop() > 0);
	});
	try {
		$('.js-example-basic-multiple').select2({
			placeholder: "Phương thức liên hệ mong muốn",
		});

	} catch (error) {

	}
	$(window).scroll(function () {
		if ($(this).scrollTop()) {
			$('.back-to-top').show();
		} else {
			$('.back-to-top').hide();
		}
	});
	$('.back-to-top').on('click', function () {
		$("html, body").animate({
			scrollTop: 0
		}, 1000)
	})

	if ($('.collection-detail').length > 0) {
		var banner = $('.collection-detail').data('banner')
		if (banner !== '') {
			$('.banner img').attr('src', banner)
		}
	}




	$(".cookie-disclaimer-fixed .close").on("click", function () {
		$(".cookie-disclaimer-fixed").hide();
	});

	$(".form-subscription-fixed .close").on("click", function () {
		setCookie("isHidden", true, 1);
		$(".form-subscription-fixed").hide();
	});

	if (getCookie("isHidden") === true) {
		$(".form-subscription-fixed").hide();
	}

	if (getCookie("isHidden") === null) {
		setTimeout(() => {
			$(".cookie-disclaimer-fixed").show();
			$(".form-subscription-fixed").show();
		}, 2000);
	}

	$('.collection-detail-contact').insertAfter($('.collection-detail'))

	// eraseCookie("isHidden")

});

function menuInit() {
	$(".menu-toggle").on("click", function () {
		$(this).toggleClass("active");
		$(this).find(".text").toggleText("menu", "close");
		$(".sidemenu-wrap").toggleClass("open");
		$(".offcanvas-overlay").fadeToggle();
	});

	$(".offcanvas-overlay").on("click", function () {
		$(".menu-toggle").removeClass("active");
		$(".sidemenu-wrap").removeClass("open");
		$(this).fadeOut()
	});

	$(".menu li").each(function (index, ele) {
		$(ele).css({
			'transitionDelay': 0.3 * (index + 1) / 2 + 's'
		});
	});
}


function setBackground() {
	$("[setBackground]").each(function () {
		var background = $(this).attr("setBackground");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-size": "cover",
			"background-position": "center center",
		});
	});
	$("[setBackgroundRepeat]").each(function () {
		var background = $(this).attr("setBackgroundRepeat");
		$(this).css({
			"background-image": "url(" + background + ")",
			"background-repeat": "repeat",
		});
	});
}


$.fn.extend({
	toggleText: function (a, b) {
		return this.text(this.text() == b ? a : b);
	},
});

function setCookie(key, value, expiry) {
	var expires = new Date();
	expires.setTime(expires.getTime() + expiry * 24 * 60 * 60 * 1000);
	document.cookie = key + "=" + value + ";expires=" + expires.toUTCString();
}

function getCookie(key) {
	var keyValue = document.cookie.match("(^|;) ?" + key + "=([^;]*)(;|$)");
	return keyValue ? keyValue[2] : null;
}

function eraseCookie(key) {
	var keyValue = getCookie(key);
	setCookie(key, keyValue, "-1");
}

function collapseComponent() {
	$(document).on("click", ".collapse-trigger", function () {
		$(this).find("span").toggleClass("rr-icon-plus rr-icon-minus");
		$(this).next().slideToggle();
		$(this).toggleClass("active");
		return false;
	});
}